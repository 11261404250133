import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'alert',
    'bedIn',
    'bedOut',
    'card',
    'escalation',
    'name',
  ]

  static classes = [
    'escalationCard',
    'escalationName'
  ]

  connect(){
    console.debug("connected to resident card")
  }

  static values = {
    escalatedResidentTarget: String,
    inBedResidentTarget: String,
    outOfBedResidentTarget: String,
    alertResidentTarget: String,
  }

  escalationTargetConnected(element) {
    this._markForFiltering(this.escalatedResidentTargetValue)
    this._updateBedInIconVisibility()

    this.cardTarget.classList.add(this.escalationCardClass)
    this.nameTarget.classList.add(this.escalationNameClass)
  }

  escalationTargetDisconnected(element) {
    this._unmarkForFiltering(this.escalatedResidentTargetValue)
    this._updateBedInIconVisibility()

    this.cardTarget.classList.remove(this.escalationCardClass)
    this.nameTarget.classList.remove(this.escalationNameClass)
  }

  bedInTargetConnected(element) {
    this._unmarkForFiltering(this.outOfBedResidentTargetValue)
    this._markForFiltering(this.inBedResidentTargetValue)
    this._updateBedInIconVisibility()
  }

  bedInTargetDisconnected(element) {
    this._unmarkForFiltering(this.inBedResidentTargetValue)
    this._markForFiltering(this.outOfBedResidentTargetValue)
    this._updateBedInIconVisibility()
  }

  bedOutTargetConnected(_element) {
    this._markForFiltering(this.outOfBedResidentTargetValue)
    this._unmarkForFiltering(this.inBedResidentTargetValue)
    this._updateBedInIconVisibility()
  }

  alertTargetConnected(element) {
    this._markForFiltering(this.alertResidentTargetValue)
  }

  alertTargetDisconnected(element) {
    this._unmarkForFiltering(this.alertResidentTargetValue)
  }

  _updateBedInIconVisibility() {
    if(this.hasBedInTarget) {
      if(this.hasEscalationTarget) {
        this.bedInTarget.hidden = true
      } else {
        this.bedInTarget.hidden = false
      }
    }
  }

  _markForFiltering(mark) {
    this.element.setAttribute('data-site--shared-residence--dashboard-target', this._addTargetForFiltering(this.element.getAttribute("data-site--shared-residence--dashboard-target"), mark))
  }

  _unmarkForFiltering(mark){
    this.element.setAttribute('data-site--shared-residence--dashboard-target', this._removeTargetForFiltering(this.element.getAttribute("data-site--shared-residence--dashboard-target"), mark))
  }

  

  // takes an attribute with space separted values
  // "value1 value2" and adds the value to it
  _addTargetForFiltering(attribute, value) {
    var attributes = attribute.split(' ')
    attributes.push(value)

    return Array.from(new Set(attributes)).join(' ')
  }

  // takes an attribute with space separted values
  // "value1 value2" and removes the valueTorRemove from it
  _removeTargetForFiltering(attribute, valueToRemove){
    var attributes = attribute.split(' ')

    var filtered = attributes.filter(function(value, index, arr){
      return value != valueToRemove;
    });

    return filtered.join(' ')
  }
}
